import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Should People with Androgenic Alopecia Worry About DMDM Hydantoin?",
  "path": "/blog/androgenic-alopecia-and-dmdm-hydantoin",
  "date": "2021-07-09"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Brand names TRESemmé and OGX have come under fire recently for having a formadelhyde-releasing preservative found in many cosmetics called DMDM Hydantoin. I had some OGX products that I used between washes with Nizoral (a necessary evil) and someone I was dating told me there's a lawsuit stating that it causes you to lose your hair.`}</p>
    <p><em parentName="p">{`Damn!`}</em>{` I picked this from my grocery store thinking that it was harmless and even potentially good for my hair. I mainly used the conditioner, which is sulfate-free, tea tree oil (antifungal like ketoconazol), and peppermint oil (natural hair growth stimulant some might suggest as an alternative to `}<a parentName="p" {...{
        "href": "/products/minoxidil"
      }}>{`minoxidil`}</a>{`).`}</p>
    <p>{`I didn't want to throw out the bottles immediately, so I checked out the lawsuit and here's an excerpt where the main claim is made:`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Plaintiff purchased the Products because of Johnson & Johnson’s uniform false representation that the Products would smooth, nourish, soften, repair, and/or revive her hair. Undisclosed by Defendant to Plaintiff and Class Members and therefore unknown to Plaintiff and Class Members, the Products contain an ingredient or combination of ingredients that causes significant hair loss and or scalp irritation upon proper application. At least one ingredient in the Products, DMDM hydantoin, is a formaldehyde donor known to slowly leach formaldehyde when coming into contact with water."`}</p>
    </blockquote>
    <p>{`It sounds to me like they're conflating marketing claims with an ingredient that could be considered a carcinogen. You can read about formadelhyde on the `}<a href="https://www.atsdr.cdc.gov/formaldehyde/" target="_blank">{`CDC`}</a>{`'s website. Basically, we are exposed to formadelhyde all day, every day, through food, clothes, furniture, cars, cigarettes, and smog.`}</p>
    <p>{`Formadelhyde even has a strong smell that can be smelled even at low levels. If you haven't put it together yet, I'll help: the dose makes the poison.`}</p>
    <h2>{`Scalp Irritation`}</h2>
    <p>{`Formadelhyde probably isn't killing you or your follicles any faster than things you're already exposed to. I've never popped open a shampoo and got a whiff of that weird pickly smell. Formadelhyde is also a gas, so even if there is some residual conditioner on your hair, whether by accident or choice, it probably isn't being absorbed into your body by your scalp.`}</p>
    <p>{`However, people do have varying levels of sensitivity towards formadelhyde, so some of the anecdotal evidence might not necessarily be untrue. People who are sensitive may experience scalp irritation, which might make one want to scratch their scalp which can damage your skin and follicles, but if you are suffering from hair loss, I would suggest exercising some prudence and make sure you are checking for other potential causes. Seborrheic dermatitis (dandruff) can make you want to scratch your scalp, which is what I use Nizoral for. Ketoconazole, the active ingredient, is an antifungal to help control the flaking and scratching. I'd honestly start there before accusing DMDM Hydantoin. There is a reason why it is considered one of "The Big 3" of hair loss.`}</p>
    <p>{`Anyone can start a lawsuit, so don't let the tweeters make you feel you need to throw out all your products. Many people will share things without doing a single ounce of research.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      